import { theme } from '@printi/printi-components';
import styled from 'styled-components';
const { breakpoint, borders, color, space, font } = theme;

export const Footer = styled.footer`
  background-color: ${color.grayscale.white};
`;

export const FooterLinks = styled.div`
  padding: ${space.xl2} ${space.md4};
  border-bottom: ${borders.sizes.sm} solid ${color.grayscale.lighter};
`;

export const NavSocialLink = styled.nav`
  margin-bottom: ${space.lg};
`;

const getTypeColor = (type) => {
  if (type === 'facebook') return '#3b5998';
  if (type === 'twitter') return '#00aced';
  if (type === 'linkedin') return '#0077b5';
  if (type === 'instagram') return '#ed4956';
  if (type === 'pinterest') return '#bd081c';
  if (type === 'medium') return '#000';
  return '#3b5998';
};

export const SocialLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${space.xl};
  width: ${space.xl};
  background-color: ${color.grayscale.white};
  border: ${borders.sizes.sm} solid ${color.grayscale.lighter};
  border-radius: 50%;
  cursor: pointer;
  margin-right: ${space.sm};
  transition: 0.3s linear;
  margin-top: ${space.md};

  svg {
    width: ${space.md};
    height: ${space.md};
    fill: ${color.grayscale.darker};
    transition: fill 0.3s linear;
  }

  &:hover {
    background-color: ${(p) => getTypeColor(p.type)};

    svg {
      fill: ${color.grayscale.white};
    }
  }
`;

export const LinkList = styled.nav`
  align-content: space-between;
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  height: 30rem;
  border-top: ${borders.sizes.sm} solid ${color.grayscale.lighter};
  padding-top: ${space.xl};

  @media (max-width: ${breakpoint.sm}) {
    height: 20rem !important;
    overflow: auto;
  }

  @media (min-width: ${breakpoint.md}) {
    height: 13rem;
  }

  @media (min-width: ${breakpoint.lg}) {
    border-top: none;
    padding-top: 0;
  }

  @media (min-width: ${breakpoint.xl}) {
    height: 10rem;
    border-top: none;
    padding-top: 0;
  }
`;

export const LinkFooter = styled.a`
  font-size: ${font.size.root};
  line-height: 3rem;
  color: ${color.grayscale.black};
  cursor: pointer;
  transition: all 0.4s ease;

  &:hover,
  &:focus {
    color: ${color.primary.pure};
    text-decoration: none;
  }

  @media (min-width: ${breakpoint.md}) {
    font-size: ${font.size.small};
    line-height: 2rem;
  }
`;

export const BottomFooter = styled.div`
  padding: ${space.xl} ${space.md4};
`;

export const StampList = styled.nav`
  margin-bottom: ${space.xl};
`;

export const LinkStamp = styled.a`
  display: inline-block;
  width: auto;
  height: ${space.xl2};

  &:not(:last-child) {
    margin-right: ${space.sm};
  }
`;

export const CustomerService = styled.div`
  margin-bottom: ${space.xl};

  p {
    font-size: ${font.size.small};
    margin: 0;
  }
`;

export const PaymentList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  margin: 0 0 ${space.md};
`;

export const PaymentListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 4.375rem;
  height: ${space.xl2};
  margin-bottom: ${space.sm};
  padding: 0;
  border-radius: ${borders.radius.sm};
  border: ${borders.sizes.sm} solid ${color.grayscale.lighter};

  &:not(:last-child) {
    margin-right: ${space.sm};
  }
`;

export const CookieSettingsButton = styled.button`
  font-size: ${font.size.small};
  text-align: start;
  line-height: 1.375rem;
  color: ${color.grayscale.black};
  margin-bottom: 0.75rem;
  border: none;
  cursor: pointer;
  transition: all 0.4s ease;

  &:hover,
  &:focus {
    color: ${color.primary.pure};
    text-decoration: none;
  }

  @media (min-width: ${breakpoint.md}) {
    line-height: 2rem;
    margin-bottom: 0;
  }
`;
