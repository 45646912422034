import React, { memo, useState, useEffect, useCallback, useRef } from 'react';

import { Icon } from '@printi/printi-components';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import useLogout from './useLogout';
import * as S from './UserSideber.styles';

const UserSideber = ({ isUserSidebarOpen, openUserSidebar }) => {
  const router = useRouter();
  const printiUrl = process.env.PRINTI_URL;
  const [isLogged, setIsLogged] = useState(!!localStorage.getItem('user'));
  const { fetchLogout } = useLogout();
  const firstSidebarLinkRef = useRef();

  useEffect(() => {
    if (!localStorage.getItem('user')) {
      setIsLogged(false);
    }
  }, [router.pathname]);

  if (isUserSidebarOpen) firstSidebarLinkRef.current?.focus();

  const handleCloseUserSidebar = useCallback(
    (event) => {
      event.stopPropagation();
      openUserSidebar(false);
    },
    [openUserSidebar],
  );

  return (
    <>
      <S.Background isOpen={isUserSidebarOpen} onClick={handleCloseUserSidebar} />
      <S.UserSideber isOpen={isUserSidebarOpen}>
        {!isLogged && (
          <S.NavMenu>
            <Link href="/login" as="login" passHref>
              <S.MenuLink tabindex="0" ref={firstSidebarLinkRef}>
                <Icon name="PersonOutline" width="20" height="20" />
                Login
              </S.MenuLink>
            </Link>

            <Link href="/login#cadastro" as="login#cadastro" passHref>
              <S.MenuLink tabindex="0">
                <Icon name="PersonAddOutline" width="20" height="20" />
                Cadastro
              </S.MenuLink>
            </Link>
            <Link href="/quem-somos" as="quem-somos" passHref>
              <S.MenuLink tabindex="0">
                <Icon name="PeopleOutline" width="20" height="20" />
                Quem Somos
              </S.MenuLink>
            </Link>
            <Link href="/como-funciona" as="como-funciona" passHref>
              <S.MenuLink tabindex="0">
                <Icon name="BulbOutline" width="20" height="20" />
                Como funciona
              </S.MenuLink>
            </Link>
            <S.CollapseLgpd withBorder={false}>
              <S.CollapseCustom>
                <S.Lgpd>
                  <Icon name="LockOutline" width="20" height="20" />
                  LGPD
                </S.Lgpd>
                <S.DropdownContent>
                  <a href="/politica-de-privacidade">Política de Privacidade</a>
                  <hr />
                  <a className="solicitacao-titular" href="https://www.printi.com.br/solicitacao-dos-titulares">
                    Solicitação do Titular
                  </a>
                </S.DropdownContent>
              </S.CollapseCustom>
            </S.CollapseLgpd>
          </S.NavMenu>
        )}

        {isLogged && (
          <S.NavMenu>
            <S.MenuLink href={`${printiUrl}/minha-conta/pedidos`}>
              <Icon name="List" width="20" height="20" />
              Meus pedidos
            </S.MenuLink>
            <S.MenuLink href={`${printiUrl}/minha-conta/enderecos`}>
              <Icon name="Navigation2Outline" width="20" height="20" />
              Meus endereços
            </S.MenuLink>
            <S.MenuLink href={`${printiUrl}/minha-conta/meus-dados`}>
              <Icon name="FileTextOutline" width="20" height="20" />
              Meus dados
            </S.MenuLink>
            <S.MenuLink href={`${printiUrl}/minha-conta/cartoes-salvos`}>
              <Icon name="CreditCardOutline" width="20" height="20" />
              Cartões salvos
            </S.MenuLink>
            <S.MenuLink href={`${printiUrl}/minha-conta/notificacoes`}>
              <Icon name="BellOutline" width="20" height="20" />
              Notificações
            </S.MenuLink>
            <Link href="/quem-somos" as="quem-somos" passHref>
              <S.MenuLink>
                <Icon name="PeopleOutline" width="20" height="20" />
                Quem Somos
              </S.MenuLink>
            </Link>
            <Link href="/como-funciona" as="como-funciona" passHref>
              <S.MenuLink>
                <Icon name="BulbOutline" width="20" height="20" />
                Como funciona
              </S.MenuLink>
            </Link>
            <S.CollapseLgpd withBorder={false}>
              <S.CollapseCustom>
                <S.Lgpd>
                  <Icon name="LockOutline" width="20" height="20" />
                  LGPD
                </S.Lgpd>
                <S.DropdownContent>
                  <a href="/politica-de-privacidade">Política de Privacidade</a>
                  <hr />
                  <a className="solicitacao-titular" href="https://www.printi.com.br/solicitacao-dos-titulares">
                    Solicitação do Titular
                  </a>
                </S.DropdownContent>
              </S.CollapseCustom>
            </S.CollapseLgpd>
            <S.ButtonLogout onClick={fetchLogout}>
              <Icon name="LogOutOutline" width="20" height="20" />
              Sair
            </S.ButtonLogout>
          </S.NavMenu>
        )}

        <S.Close aria-label="Fechar menu" onClick={(e) => handleCloseUserSidebar(e)}>
          <Icon name="Close" fill="#242b33" />
        </S.Close>
      </S.UserSideber>
    </>
  );
};

UserSideber.propTypes = {
  isUserSidebarOpen: PropTypes.bool.isRequired,
  openUserSidebar: PropTypes.func.isRequired,
};

export default memo(UserSideber);
