import React, { memo, useState, useEffect, useCallback } from 'react';

import { Icon } from '@printi/printi-components';
import { api } from '@utils';
import Link from 'next/link';
import PropTypes from 'prop-types';

import * as S from './Header.styles';

const Cart = ({ updateCart }) => {
  const [cartItems, setCartItems] = useState(0);

  const fetchCart = useCallback(async () => {
    await api.get('/v2/cart').then(({ data }) => {
      const itemsObject = data.cart.items;
      const list = Object.keys(itemsObject).map((key) => itemsObject[key]);
      const count = list.reduce((acc, current) => acc + current.quantity, 0);

      setCartItems(count);
    });
  }, []);

  useEffect(() => {
    fetchCart();
  }, [fetchCart, updateCart]);

  return (
    <Link href="/meu-carrinho" as="meu-carrinho">
      <S.LinkHeader aria-label="Meu carrinho">
        <Icon name={cartItems ? 'ShoppingCart' : 'ShoppingCartOutline'} width="24" height="24" />
        {cartItems ? <S.CartNumber>{cartItems}</S.CartNumber> : null}
      </S.LinkHeader>
    </Link>
  );
};

Cart.propTypes = {
  updateCart: PropTypes.number,
};

export default memo(Cart);
