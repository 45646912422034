import React, { memo } from 'react';

import PropTypes from 'prop-types';

const Logo = ({ name, size, ...rest }) => {
  const showNewLogo = true;

  if (name === 'footer') {
    const sizes = {
      sm: {
        width: 81,
        height: 25,
      },
      md: {
        width: 115,
        height: 35,
      },
      lg: {
        width: 173,
        height: 53,
      },
    };

    const sizeProp = {
      width: sizes[size].width,
      height: sizes[size].height,
    };

    return (
      <svg viewBox="99 103 682 206.02792358398438" {...sizeProp} {...rest}>
        <path
          fill="#8000ff"
          d="M330 211V103h38c27 0 51 19 51 54s-24 54-52 54zm37-13c20 0 37-14 37-41s-17-41-37-41h-22v82zM499 190c-4 13-15 23-32 23-20 0-37-14-37-39 0-23 16-39 35-39 22 0 35 16 35 39a32 32 0 010 4h-55c0 13 9 23 22 23 11 0 17-7 20-15zm-14-23c0-11-7-19-20-19-12 0-19 9-20 19zM525 174c0 17 11 26 23 26 13 0 18-9 20-15l13 5c-4 11-14 23-33 23-22 0-37-17-37-39 0-23 16-39 37-39 19 0 29 13 32 24l-13 5c-2-7-7-15-19-15s-23 8-23 25zM661 174c0 23-16 39-37 39s-38-16-38-39c0-22 16-39 38-39s37 17 37 39zm-15 0c0-17-10-26-22-26s-23 9-23 26 10 26 23 26 22-9 22-26zM712 151a45 45 0 00-6 0c-12 0-20 7-20 23v37h-14v-73h14v13c5-12 14-16 22-16a26 26 0 014 1zM740 169l20-3c4 0 6-3 6-5 0-7-5-13-16-13-10 0-16 6-17 14l-14-3c2-14 15-24 30-24 22 0 31 13 31 27v37a69 69 0 001 12h-14a54 54 0 01-1-10c-3 5-10 12-24 12s-24-10-24-22c0-13 10-20 22-22zm26 11v-4l-23 4c-6 0-10 4-10 11 0 5 4 10 11 10 12 0 22-5 22-21zM257 139l-47-1-47 1a7 7 0 00-6 6l-11 74a10 10 0 0010 11h1c14 0 25 13 23 27a41 41 0 01-40 38 5 5 0 00-5 4 5 5 0 003 5 63 63 0 0023 5 62 62 0 0021-3c38-13 63-46 69-84l5-39 6-38a5 5 0 00-5-6z"
        />
        <path
          fill="#8000ff"
          d="M297 105v202a2 2 0 01-2 2h-70a2 2 0 01-2-2v-12a2 2 0 012-2h56V119H115v174h8a2 2 0 012 2v12a2 2 0 01-2 2h-22a2 2 0 01-2-2V105a2 2 0 012-2h194a2 2 0 012 2z"
        />
        <path
          fill="#5a5c5e"
          d="M339 282v26h-9v-65h23c12 0 20 9 20 20s-8 19-20 19zm13-8c7 0 12-5 12-11 0-7-5-12-12-12h-13v23zM403 272a27 27 0 00-4 0c-7 0-12 4-12 14v22h-8v-44h8v8c3-7 9-9 13-9a16 16 0 013 0zM413 243a6 6 0 11-6 7 6 6 0 016-7zM408 264h9v44h-9zM433 308h-8v-44h8v6a15 15 0 0114-7c10 0 15 7 15 17v28h-8v-26c0-6-3-11-10-11s-11 5-11 12zM485 264h9v8h-9v23c0 4 1 6 6 6a17 17 0 003 0v7a18 18 0 01-6 1c-7 0-12-4-12-12v-25h-8v-8h2c5 0 7-3 7-7v-6h8zM505 243a6 6 0 11-6 7 6 6 0 016-7zM500 264h9v44h-9z"
        />
      </svg>
    );
  }

  if (name === 'symbol') {
    const sizes = {
      sm: {
        width: 66,
        height: 69,
      },
      md: {
        width: 94,
        height: 98,
      },
      lg: {
        width: 141,
        height: 147,
      },
    };

    const sizeProp = {
      width: sizes[size].width,
      height: sizes[size].height,
    };

    return (
      <svg viewBox="113 117 229 238" {...sizeProp} {...rest}>
        <g fill="#8000ff">
          <path d="M295 160q-26-2-53-2t-54 2a8 8 0 00-8 7l-12 84a11 11 0 0011 13h2c16 1 28 15 26 31a47 47 0 01-46 43 6 6 0 00-6 5v1a6 6 0 004 6 73 73 0 0026 5 72 72 0 0024-3c44-15 73-53 79-97l7-44 6-44a6 6 0 00-6-7z" />
          <path d="M342 121v232a2 2 0 01-3 2h-80a2 2 0 01-2-2v-15a2 2 0 012-3h63V138H134v197h8a2 2 0 012 3v15a2 2 0 01-2 2h-26a2 2 0 01-2-2V121a2 2 0 012-3h223a2 2 0 013 3z" />
        </g>
      </svg>
    );
  }

  if (!name) {
    const sizes = {
      sm: {
        width: 84,
        height: 19,
      },
      md: {
        width: 128,
        height: 41,
      },
      lg: {
        width: 180,
        height: 41,
      },
    };

    const sizeProp = {
      width: sizes[size].width,
      height: sizes[size].height,
    };

    const NewLogo = () => (
      <svg {...sizeProp} {...rest} viewBox="0 0 133 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title id="Decora-Legal-Logo">Decora Printi</title>
        <path
          d="M45.0305 21.944V0.906738H52.3883C57.7305 0.906738 62.3879 4.55538 62.3879 11.4703C62.3879 18.3539 57.6701 21.944 52.3298 21.944H45.0305ZM52.24 19.3626C56.1286 19.3626 59.391 16.752 59.391 11.4703C59.391 6.15728 56.1872 3.48811 52.3005 3.48811H47.9377V19.3626H52.24Z"
          fill="#8000FF"
        />
        <path
          d="M78.1024 17.8701C77.3005 20.3929 75.0157 22.3811 71.6929 22.3811C67.8648 22.3811 64.5127 19.5929 64.5127 14.756C64.5127 10.2684 67.7457 7.21875 71.3417 7.21875C75.7337 7.21875 78.1961 10.245 78.1961 14.6955C78.1975 14.9931 78.178 15.2904 78.1376 15.5853H67.3906C67.4492 18.1374 69.2891 19.9188 71.6929 19.9188C74.007 19.9188 75.164 18.6427 75.6986 17.0408L78.1024 17.8701ZM75.2908 13.3395C75.2323 11.2908 73.8958 9.6889 71.3749 9.6889C69.0335 9.6889 67.6053 11.4996 67.4862 13.3395H75.2908Z"
          fill="#8000FF"
        />
        <path
          d="M83.1697 14.7931C83.1697 18.0574 85.2165 19.8076 87.5325 19.8076C90.2016 19.8076 91.1811 17.9989 91.507 16.8711L93.9713 17.9384C93.2884 19.9851 91.2397 22.3889 87.5325 22.3889C83.3785 22.3889 80.3132 19.1247 80.3132 14.7931C80.3132 10.3425 83.4351 7.22656 87.4935 7.22656C91.2923 7.22656 93.2513 9.60111 93.8444 11.7669L91.3216 12.8342C90.9665 11.4391 89.9265 9.80793 87.4935 9.80793C85.2418 9.80793 83.1697 11.4703 83.1697 14.7931Z"
          fill="#8000FF"
        />
        <path
          d="M109.631 14.7931C109.631 19.1559 106.605 22.3889 102.332 22.3889C98.0588 22.3889 95.0326 19.1559 95.0326 14.7931C95.0326 10.4616 98.0588 7.22656 102.332 7.22656C106.605 7.22656 109.631 10.4557 109.631 14.7931ZM106.782 14.7931C106.782 11.4996 104.704 9.72013 102.332 9.72013C99.9592 9.72013 97.8813 11.4996 97.8813 14.7931C97.8813 18.1101 99.9573 19.8973 102.332 19.8973C104.706 19.8973 106.782 18.1159 106.782 14.7931Z"
          fill="#8000FF"
        />
        <path
          d="M119.678 10.2821C119.274 10.2248 118.867 10.1954 118.46 10.1943C116.119 10.1943 114.544 11.6479 114.544 14.7931V21.9441H111.754V7.67142H114.486V10.165C115.525 7.968 117.305 7.22656 118.759 7.22656C119.068 7.22846 119.376 7.2585 119.679 7.31632L119.678 10.2821Z"
          fill="#8000FF"
        />
        <path
          d="M125.071 13.7844L128.928 13.2205C129.788 13.1015 130.026 12.6566 130.026 12.1513C130.026 10.7581 129.106 9.60111 126.94 9.60111C124.989 9.60111 123.884 10.8167 123.705 12.4791L121.036 11.8547C121.332 9.12308 123.795 7.22656 126.889 7.22656C131.162 7.22656 132.822 9.65964 132.822 12.4498V19.6007C132.814 20.3849 132.873 21.1683 133 21.9421H130.268C130.159 21.3062 130.109 20.6615 130.118 20.0163C129.496 21.0251 128.071 22.3889 125.519 22.3889C122.612 22.3889 120.743 20.3714 120.743 18.1159C120.739 15.5638 122.608 14.1395 125.071 13.7844ZM130.026 15.8311V15.1794L125.664 15.8311C124.477 16.0087 123.588 16.6916 123.588 17.9676C123.588 19.0369 124.477 20.0456 125.902 20.0456C128.187 20.0456 130.026 18.9471 130.026 15.8311Z"
          fill="#8000FF"
        />
        <path
          d="M30.793 7.92893C27.7882 7.74942 24.7509 7.65967 21.6811 7.65967C18.6113 7.65967 15.5486 7.75007 12.4931 7.93088C12.1878 7.95502 11.8995 8.08106 11.6745 8.28877C11.4494 8.49649 11.3007 8.77381 11.2522 9.0762L9.23083 23.4581C9.19286 23.7293 9.21354 24.0055 9.29146 24.2681C9.36937 24.5306 9.50272 24.7734 9.6825 24.98C9.86227 25.1865 10.0843 25.3522 10.3335 25.4656C10.5828 25.579 10.8535 25.6377 11.1273 25.6375C11.1273 25.6375 11.2893 25.6375 11.342 25.6375C14.0521 25.7371 16.0735 28.1994 15.8706 30.9056C15.7284 32.8985 14.8382 34.7639 13.3785 36.128C11.9188 37.4922 9.99752 38.2542 7.99965 38.2615C7.76264 38.2592 7.5329 38.3433 7.35337 38.4981C7.17383 38.6528 7.05678 38.8677 7.02408 39.1024V39.1688C6.99389 39.3843 7.03664 39.6037 7.14554 39.7922C7.25443 39.9806 7.42321 40.1272 7.62503 40.2087C9.05624 40.7931 10.5845 41.1035 12.1302 41.1238C13.5037 41.1408 14.8701 40.9244 16.1711 40.4838C23.5854 37.9942 28.5511 31.5085 29.6067 24.1274L30.6642 16.5979L31.7217 9.0762C31.7464 8.93836 31.7412 8.79681 31.7063 8.66118C31.6715 8.52555 31.6078 8.39901 31.5197 8.29016C31.4316 8.18132 31.3211 8.09272 31.1957 8.03038C31.0703 7.96805 30.9329 7.93345 30.793 7.92893Z"
          fill="#8000FF"
        />
        <path
          d="M38.6581 1.27356V40.7531C38.6581 40.8505 38.6195 40.944 38.5508 41.0131C38.482 41.0823 38.3887 41.1213 38.2913 41.1218H24.6332C24.5358 41.1213 24.4425 41.0823 24.3737 41.0131C24.305 40.944 24.2664 40.8505 24.2664 40.7531V38.3044C24.2664 38.2071 24.3051 38.1138 24.3739 38.045C24.4427 37.9762 24.536 37.9376 24.6332 37.9376H35.4699V4.09101H3.17842V37.9376H4.69056C4.73889 37.9373 4.7868 37.9466 4.83153 37.9649C4.87626 37.9833 4.91693 38.0102 4.9512 38.0443C4.98547 38.0784 5.01266 38.1189 5.03122 38.1636C5.04977 38.2082 5.05933 38.2561 5.05933 38.3044V40.7531C5.05933 40.8509 5.02047 40.9447 4.95132 41.0138C4.88216 41.083 4.78836 41.1218 4.69056 41.1218H0.366815C0.269351 41.1213 0.176054 41.0823 0.107318 41.0131C0.0385824 40.944 -1.36424e-06 40.8505 0 40.7531V1.27356C0 1.17627 0.038646 1.08297 0.107437 1.01418C0.176229 0.945386 0.26953 0.906738 0.366815 0.906738H38.2971C38.3934 0.908275 38.4852 0.947598 38.5527 1.01622C38.6203 1.08485 38.6581 1.17728 38.6581 1.27356Z"
          fill="#8000FF"
        />
        <path
          d="M46.7651 35.7269V40.8623H45.0305V28.3066H49.6001C51.92 28.3066 53.477 29.9183 53.477 32.0255C53.477 34.1328 51.9161 35.7327 49.6001 35.7327L46.7651 35.7269ZM49.2801 34.1659C50.7864 34.1659 51.7073 33.3328 51.7073 32.0411C51.7073 30.7124 50.7864 29.8617 49.2801 29.8617H46.7651V34.1659H49.2801Z"
          fill="#4D4D4D"
        />
        <path
          d="M59.27 33.8967C59.0296 33.8623 58.787 33.8447 58.5442 33.844C57.1432 33.844 56.2028 34.7142 56.2028 36.5912V40.8623H54.5365V32.3397H56.1696V33.8284C56.7901 32.5172 57.8534 32.0724 58.7217 32.0724C58.9057 32.0739 59.0891 32.0915 59.27 32.1251V33.8967Z"
          fill="#4D4D4D"
        />
        <path
          d="M61.1763 28.3067C61.413 28.3048 61.6448 28.3731 61.8426 28.503C62.0404 28.6329 62.1952 28.8185 62.2875 29.0364C62.3797 29.2544 62.4053 29.4947 62.3609 29.7272C62.3164 29.9596 62.2041 30.1736 62.038 30.3422C61.8719 30.5107 61.6595 30.6262 61.4278 30.6741C61.196 30.7219 60.9553 30.6999 60.736 30.6109C60.5168 30.5218 60.3289 30.3698 60.1961 30.1739C60.0633 29.978 59.9916 29.7472 59.99 29.5105C59.9887 29.3536 60.0184 29.1979 60.0774 29.0524C60.1363 28.9069 60.2234 28.7744 60.3336 28.6626C60.4437 28.5508 60.5749 28.4618 60.7195 28.4007C60.8641 28.3397 61.0194 28.3077 61.1763 28.3067Z"
          fill="#4D4D4D"
        />
        <path d="M61.9801 32.3397H60.3314V40.8623H61.9801V32.3397Z" fill="#4D4D4D" />
        <path
          d="M65.2385 40.8624H63.5723V32.3398H65.2015V33.5612C65.4649 33.1021 65.8478 32.723 66.3096 32.4642C66.7714 32.2055 67.2947 32.0769 67.8238 32.092C69.8628 32.092 70.8715 33.5612 70.8715 35.4577V40.8624H69.2072V35.7407C69.2072 34.5544 68.7096 33.5944 67.2229 33.5944C65.9117 33.5944 65.2385 34.6383 65.2385 35.9358V40.8624Z"
          fill="#4D4D4D"
        />
        <path
          d="M75.2285 32.3397H77.0723V33.846H75.2285V38.4468C75.2285 39.2273 75.5485 39.6175 76.4167 39.6175C76.637 39.6153 76.8566 39.5918 77.0723 39.5473V40.9638C76.7054 41.0772 76.3227 41.1312 75.9387 41.1238C74.4851 41.1238 73.5641 40.238 73.5641 38.6966V33.846H71.933V32.3397H72.3954C73.3163 32.3397 73.7066 31.7719 73.7066 31.0286V29.8188H75.2285V32.3397Z"
          fill="#4D4D4D"
        />
        <path
          d="M79.1171 28.3067C79.3537 28.3051 79.5854 28.3738 79.7829 28.5039C79.9804 28.634 80.135 28.8198 80.2269 29.0378C80.3189 29.2557 80.3441 29.4961 80.2995 29.7284C80.2548 29.9607 80.1423 30.1745 79.9761 30.3429C79.81 30.5113 79.5976 30.6266 79.3659 30.6742C79.1342 30.7219 78.8936 30.6998 78.6744 30.6107C78.4553 30.5216 78.2675 30.3695 78.1348 30.1737C78.0021 29.9779 77.9304 29.7471 77.9289 29.5105C77.9276 29.3534 77.9573 29.1975 78.0164 29.0519C78.0755 28.9063 78.1627 28.7738 78.2731 28.6619C78.3835 28.5501 78.5149 28.4611 78.6597 28.4002C78.8045 28.3392 78.96 28.3074 79.1171 28.3067Z"
          fill="#4D4D4D"
        />
        <path d="M79.9503 32.3397H78.1981V40.8623H79.9503V32.3397Z" fill="#4D4D4D" />
      </svg>
    );

    return (
      <>
        {showNewLogo ? (
          <NewLogo />
        ) : (
          <svg viewBox="99 103 967 206.02792358398438" {...sizeProp} {...rest}>
            <title id="Decora-Printi-Logo">Decora Printi</title>
            <g fill="#8000ff">
              <path d="M99 292V120h60c44 0 82 30 82 86 0 57-39 86-82 86zm59-21c32 0 58-21 58-65 0-43-26-65-58-65h-35v130zM369 259c-7 20-25 36-53 36-31 0-58-22-58-62 0-36 26-61 56-61 35 0 55 24 55 61a51 51 0 010 7h-88c1 21 16 35 35 35s29-10 33-23zm-23-37c-1-17-12-30-32-30-19 0-31 15-32 30zM410 233c0 27 17 41 36 41 21 0 29-14 32-24l20 9c-6 17-22 36-52 36-34 0-59-26-59-62s25-61 58-61c31 0 47 19 52 37l-21 9c-2-12-11-25-31-25-18 0-35 13-35 40zM626 233c0 36-25 62-60 62s-59-26-59-62c0-35 24-61 59-61s60 26 60 61zm-24 0c0-26-16-41-36-41s-36 15-36 41c0 28 17 42 36 42s36-14 36-42zM708 198a72 72 0 00-10 0c-19 0-32 10-32 35v59h-23V175h22v21c9-18 23-22 35-22a43 43 0 018 0zM752 225l31-4c7-1 9-5 9-9 0-11-8-21-25-21-16 0-25 10-27 24l-21-5c2-23 22-38 47-38 35 0 49 20 49 42v59a109 109 0 001 19h-22a84 84 0 01-1-16c-5 8-17 19-38 19-24 0-39-16-39-34 0-21 15-33 36-36zm40 17v-5l-36 5c-9 1-17 7-17 17 0 9 8 17 19 17 19 0 34-9 34-34zM1026 139l-47-1-47 1a7 7 0 00-6 6l-11 74a10 10 0 0010 11h1c14 0 24 13 23 27a41 41 0 01-40 38 5 5 0 00-5 4 5 5 0 003 5 63 63 0 0023 5 62 62 0 0021-3c38-13 63-46 69-84l5-39 6-38a5 5 0 00-5-6z" />
              <path d="M1066 105v202a2 2 0 01-2 2h-70a2 2 0 01-2-2v-13a2 2 0 012-2h55V120H885v172h7a2 2 0 012 2v13a2 2 0 01-2 2h-22a2 2 0 01-2-2V105a2 2 0 012-2h194a2 2 0 012 2z" />
            </g>
          </svg>
        )}
      </>
    );
  }

  return null;
};

Logo.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

Logo.defaultProps = {
  size: 'md',
};

export default memo(Logo);
