import React, { memo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Text, Loader } from '@printi/printi-components';
import { footerLinksLoadRequest } from '@store/footerLinks/actions';
import Link from 'next/link';

import Logo from '../Logo';
import ModalCookieConsent from '../ModalCookieConsent';
import * as S from './Footer.styles';
import IconSocial from './icons/IconSocial';

const FooterLinks = () => {
  const [showModalCookieConsent, setShowModalCookieConsent] = useState(false);
  const dispatch = useDispatch();
  const { footerLinks, loading, isLoaded } = useSelector((state) => state.footerLinks);

  const toggleShowCookieConsentModal = useCallback(() => {
    setShowModalCookieConsent((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (!footerLinks && !isLoaded && !loading) {
      dispatch(footerLinksLoadRequest());
    }
  }, [footerLinks, dispatch, isLoaded, loading]);

  return (
    <S.FooterLinks>
      <Row>
        <Col xs={12} lg={4}>
          <Logo alt="Logo Decora" />
          <S.NavSocialLink>
            <S.SocialLink
              type="facebook"
              href="https://www.facebook.com/PrintiBR/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <IconSocial name="facebook" />
            </S.SocialLink>
            <S.SocialLink
              type="twitter"
              href="https://twitter.com/Printi"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <IconSocial name="twitter" />
            </S.SocialLink>
            <S.SocialLink
              type="linkedin"
              href="https://www.linkedin.com/company/printi"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Linkedin"
            >
              <IconSocial name="linkedin" />
            </S.SocialLink>
            <S.SocialLink
              type="instagram"
              href="https://www.instagram.com/printibr/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <IconSocial name="instagram" />
            </S.SocialLink>
            <S.SocialLink
              type="pinterest"
              href="https://br.pinterest.com/printi/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Pinterest"
            >
              <IconSocial name="pinterest" />
            </S.SocialLink>
            <S.SocialLink
              type="medium"
              href="https://medium.com/printi-tech"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Medium"
            >
              <IconSocial name="medium" />
            </S.SocialLink>
          </S.NavSocialLink>

          <Text size="sm" margin="0 0 1rem">
            © {new Date().getFullYear()} - Printi. Todos os direitos reservados
          </Text>
        </Col>
        <Col xs={12} lg={8}>
          {loading ? (
            <Loader size="small" wrapper="1.5rem" />
          ) : (
            <S.LinkList>
              {footerLinks &&
                footerLinks.map(({ slug, position, title, footer_link, target }) => {
                  if (target === '_blank') {
                    return (
                      <S.LinkFooter key={position || slug} href={footer_link} target="_blank" rel="noopener noreferrer">
                        {title}
                      </S.LinkFooter>
                    );
                  }

                  return (
                    <Link key={position || slug} href={footer_link} to={footer_link} passHref>
                      <S.LinkFooter>{title}</S.LinkFooter>
                    </Link>
                  );
                })}
              <S.CookieSettingsButton onClick={toggleShowCookieConsentModal}>
                Política de Cookies
              </S.CookieSettingsButton>
              {showModalCookieConsent && <ModalCookieConsent hideBanner close={toggleShowCookieConsentModal} />}
            </S.LinkList>
          )}
        </Col>
      </Row>
    </S.FooterLinks>
  );
};

export default memo(FooterLinks);
