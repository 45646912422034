import React, { memo, useEffect, useState } from 'react';

import { Icon, Text } from '@printi/printi-components';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import Logo from '../Logo';
import UserSidebar from '../UserSidebar';
import Cart from './Cart';
import * as S from './Header.styles';

const Header = ({ updateCart }) => {
  const [isUserSidebarOpen, setIsUserSidebarOpen] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (router.asPath) {
      setIsUserSidebarOpen(false);
    }
  }, [router, setIsUserSidebarOpen]);

  return (
    <S.Header>
      <S.HeaderWrapper>
        <S.HeaderWrapperMenuLogo>
          <div>
            <S.LinkHeader aria-label="Abrir menu" onClick={() => setIsUserSidebarOpen(true)}>
              <Icon name="MenuOutline" width="24" height="24" />
              {isUserSidebarOpen && (
                <UserSidebar isUserSidebarOpen={isUserSidebarOpen} openUserSidebar={setIsUserSidebarOpen} />
              )}
            </S.LinkHeader>
          </div>
          <S.HeaderLogo>
            <Link href="/" as="">
              <S.Logo>
                <S.LogoTitle>
                  <Logo />
                </S.LogoTitle>
              </S.Logo>
            </Link>
          </S.HeaderLogo>
        </S.HeaderWrapperMenuLogo>
        <S.HeaderCart>
          <S.ContainerRightLinks>
            <S.MainHeaderRight>
              <Link href={`${process.env.PRINTI_URL}/central-de-ajuda`} passHref>
                <Text weight="bold" as="a" rel="nofollow" target="_blank">
                  Ajuda
                </Text>
              </Link>
            </S.MainHeaderRight>
            <S.MainHeaderRight>
              <Cart updateCart={updateCart} />
            </S.MainHeaderRight>
          </S.ContainerRightLinks>
        </S.HeaderCart>
      </S.HeaderWrapper>
    </S.Header>
  );
};

Header.propTypes = {
  updateCart: PropTypes.number,
};

export default memo(Header);
