import { theme } from '@printi/printi-components';
import styled from 'styled-components';
const { color, font, space } = theme;

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  padding: ${space.sm} 0;
  background-color: ${color.grayscale.white};
`;

export const Logo = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const LogoTitle = styled.h1`
  font-size: 0;
  margin-bottom: 0;
`;

export const MainHeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 1.5rem;
  cursor: pointer;
`;

export const ContainerRightLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const LinkHeader = styled.button`
  display: block;
  position: relative;
  border: 0;
  padding: 0;
  color: ${color.grayscale.black};
  cursor: pointer;

  &:not(:first-of-type) {
    margin-left: ${space.md};
  }
`;

export const CartNumber = styled.span`
  display: block;
  position: absolute;
  top: -0.3125rem;
  right: -0.3125rem;
  width: 1.125rem;
  height: 1.125rem;
  background-color: ${color.danger.pure};
  color: ${color.grayscale.white};
  font-size: ${font.size.xxsmall};
  border-radius: 50%;
  text-align: center;
  line-height: 1.125rem;
  font-weight: ${font.weight.bold};
`;

export const Cta = styled.p`
  margin: 0;
  padding: ${space.xs} 0;
  background-color: ${color.info.pure};
  font-weight: ${font.weight.bold};
  text-align: center;
  color: ${color.grayscale.white};
  font-size: ${font.size.root};
`;

export const HeaderCart = styled('div')`
  width: 44%;
  display: flex;
  justify-content: flex-end;
`;

export const HeaderWrapperMenuLogo = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 56%;

  @media (max-width: 767px) {
    width: 64%;
  }
  @media (max-width: 600px) {
    justify-content: flex-start;
  }
`;

export const HeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 24px;
  @media (max-width: 600px) {
  }
`;

export const HeaderLogo = styled('div')`
  @media (max-width: 600px) {
    margin-left: 16px;
  }
`;
