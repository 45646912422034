import React, { memo } from 'react';
import { Helmet } from 'react-helmet';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

const Headers = ({ seo }) => {
  const router = useRouter();
  const is_home = router.pathname === '/';

  return (
    <Helmet>
      <title>{seo.title || 'Decora Printi | Você só precisa imaginar'}</title>
      <meta name="description" content={seo.description || 'Monte seus quadros com o Decora Printi'} />
      <meta name="robots" content={seo.robots || 'index, follow'} />
      <meta name="copyright" content={seo.copyright || 'Decora Printi'} />
      <meta name="abstract" content={seo.abstract || 'Decora Printi | Você só precisa imaginar'} />
      <meta property="og:type" content={seo.type || 'website'} />
      <meta property="og:title" content={seo.title || 'Decora Printi | Você só precisa imaginar'} />
      <meta property="og:url" content={seo.url || 'https://decora.printi.com.br'} />
      <meta property="og:site_name" content={seo.copyright || 'Decora Printi'} />
      <meta
        property="og:image"
        content={seo.fbImage || 'https://www.printi.com.br/assets/final/img/logos-printi/Printi-simbolo.png'}
      />
      <meta property="fb:admins" content="627008" />
      <meta property="fb:page_id" content="312936488776247" />
      {is_home && <script src="https://cdn.popupsmart.com/bundle.js" data-id="804527" async defer></script>}
    </Helmet>
  );
};

Headers.propTypes = {
  seo: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    copyright: PropTypes.string,
    abstract: PropTypes.string,
    robots: PropTypes.string,
    url: PropTypes.string,
    type: PropTypes.string,
    fbImage: PropTypes.string,
  }).isRequired,
};

export default memo(Headers);
