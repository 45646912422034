import styled from 'styled-components';

export const Page = styled.section`
  padding-top: 3.1875rem;
  background-color: #edf1f7;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
`;
