import { theme, CollapseItem, Collapse } from '@printi/printi-components';
import styled from 'styled-components';
const { borders, color, font, space } = theme;

export const Background = styled.div`
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 1;
`;

export const UserSideber = styled.menu`
  display: block;
  position: fixed;
  z-index: 1032;
  top: 0;
  left: 0;
  bottom: 0;
  width: 16rem;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  margin: 0;
  padding: ${space.xl3} 0 ${space.xl3};
  background-color: ${color.grayscale.white};
  transform: ${(p) => (p.isOpen ? 'translateX(0)' : 'translateX(-16rem)')};
  transition: all 0.27s ease;
`;

export const Close = styled.button`
  position: absolute;
  top: ${space.xs};
  right: ${space.xs};
  border-radius: 50%;
  background-color: transparent;
  border: 0;
`;

export const NavMenu = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${space.xl};
`;

export const ButtonLogout = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: ${space.xl3};
  line-height: 3rem;
  font-size: ${font.size.root};
  color: ${color.grayscale.black};
  padding: 0 ${space.sm};
  border: none;
  cursor: pointer;
  margin: 0.063rem;
`;
export const MenuLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: ${space.xl3};
  line-height: 3rem;
  font-size: ${font.size.root};
  color: ${color.grayscale.black};
  padding: 0 ${space.sm};
  border-bottom: ${borders.sizes.sm} solid ${color.grayscale.lighter};
  cursor: pointer;
  margin: 0.063rem;

  &:first-of-type {
    border-top: ${borders.sizes.sm} solid ${color.grayscale.lighter};
  }

  &:hover {
    text-decoration: none;
    background-color: ${color.info.pale};
    transition: background-color 0.3s ease;
  }

  svg {
    margin-right: ${space.xs};
  }
`;

export const CollapseLgpd = styled(Collapse)`
  padding: 0;
`;

export const CollapseCustom = styled(CollapseItem)`
  border-bottom: ${borders.sizes.sm} solid ${color.grayscale.lighter} !important;

  div {
    padding: 0;
  }
  div:first-child {
    height: auto;
  }
  svg {
    display: none;
  }
`;

export const Lgpd = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${space.xl3};
  line-height: 3rem;
  font-size: ${font.size.root};
  color: ${color.grayscale.black};
  cursor: pointer;
  padding: 0 ${space.sm};

  &:hover {
    text-decoration: none;
    background-color: ${color.info.pale};
    transition: background-color 0.3s ease;
  }

  svg {
    margin-right: ${space.xs};
    display: block !important;
  }
`;

export const DropdownContent = styled.div`
  a {
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;
    cursor: pointer;
    padding: 0 ${space.sm};
    font-size: ${font.size.root};
    height: ${space.xl};
    margin: 0.125rem;
  }
  a:hover {
    text-decoration: none;
    background-color: ${color.info.pale};
    transition: background-color 0.3s ease;
  }
  hr {
    margin: 0;
  }
`;
