import { useState, useCallback } from 'react';

import { api } from '@utils';
import { useRouter } from 'next/router';

const useLogout = () => {
  const router = useRouter();
  const [logoutLoading, setLogoutLoading] = useState(false);

  const fetchLogout = useCallback(async () => {
    setLogoutLoading(true);

    await api.put('/v2/customers/logout', {}).then(({ data }) => {
      localStorage.removeItem('user', JSON.stringify(data));

      if (router.pathname === '/') {
        router.reload(window.location.pathname);
      }

      router.push('/');
    });

    setLogoutLoading(false);
  }, [router]);

  return {
    fetchLogout,
    logoutLoading,
  };
};

export default useLogout;
