import React, { memo } from 'react';

import BottomFooter from './BottomFooter';
import * as S from './Footer.styles';
import FooterLinks from './FooterLinks';

const Footer = () => (
  <S.Footer id="footer">
    <FooterLinks />
    <BottomFooter />
  </S.Footer>
);

export default memo(Footer);
