import { theme } from '@printi/printi-components';
import styled from 'styled-components';

const { font, space } = theme;

export const CookiePolicyTitle = styled.div`
  text-align: center;
  text-decoration: underline;
`;

export const CookiePolicyContent = styled.div`
  th {
    font-weight: ${font.weight.bold};
    text-align: center;
  }

  td {
    &:last-child {
      text-align: center;
    }
  }

  th,
  td {
    width: ${space.xl3};

    &:last-child {
      width: ${space.md};
    }
  }
`;
