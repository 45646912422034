import React, { memo } from 'react';

import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import Header from '../Header';
import Head from './Head';
import * as S from './Page.styles';

const Footer = dynamic(() => import('../Footer'));
const ModalCookieConsent = dynamic(() => import('../ModalCookieConsent'));

const Page = ({ seo, children, updateCart }) => (
  <S.Page>
    <ModalCookieConsent />
    {seo && <Head seo={seo} />}
    <Header updateCart={updateCart} />
    {children}
    <Footer />
  </S.Page>
);

Page.propTypes = {
  seo: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    copyright: PropTypes.string,
    abstract: PropTypes.string,
    robots: PropTypes.string,
    canonical: PropTypes.string,
    type: PropTypes.string,
  }),
  children: PropTypes.node,
  updateCart: PropTypes.number,
};

export default memo(Page);
