import React, { memo } from 'react';

import Page from '@components/Page';
import { Row, Col, Text } from '@printi/printi-components';

import * as S from './styles';

const SEO = {
  title: 'Página não encontrada | Decora Printi',
  description: '',
  copyright: 'Decora Printi',
  abstract: 'Página não encontrada | Decora Printi',
  robots: 'noindex, nofollow',
  type: 'website',
};

const Error404 = () => (
  <Page seo={SEO}>
    <Row>
      <Col xs={12}>
        <S.Container>
          <Text size="h1" color="info" align="center" weight="bold" margin="1rem 0 2rem">
            404
          </Text>
          <Text size="xxlg" color="info" align="center" margin="1rem 0">
            acabou a tinta para
          </Text>
          <Text size="xxlg" color="info" align="center" margin="1rem 0">
            imprimir este conteúdo...
          </Text>
        </S.Container>
      </Col>
    </Row>
  </Page>
);

export default memo(Error404);
